import { createTheme } from "@fluentui/react";

export const darkTheme = createTheme({
  palette: {
    themePrimary: "#7277ba",
    themeLighterAlt: "#f8f9fc",
    themeLighter: "#e5e6f4",
    themeLight: "#cfd1ea",
    themeTertiary: "#a4a7d6",
    themeSecondary: "#8084c2",
    themeDarkAlt: "#666ba8",
    themeDark: "#565a8d",
    themeDarker: "#404268",
    neutralLighterAlt: "#2b2826",
    neutralLighter: "#33312e",
    neutralLight: "#413e3b",
    neutralQuaternaryAlt: "#4a4643",
    neutralQuaternary: "#514d49",
    neutralTertiaryAlt: "#6f6a66",
    neutralTertiary: "#e9edf3",
    neutralSecondary: "#edf0f5",
    neutralPrimaryAlt: "#f0f3f7",
    neutralPrimary: "#e0e5ee",
    neutralDark: "#f7f9fb",
    black: "#fbfcfd",
    white: "#22201e",
  },
});

export default darkTheme;
