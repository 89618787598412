import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { Icon, IRenderFunction } from "@fluentui/react";
import { Stack, Toggle, Text } from "@fluentui/react";
import { Nav, INavLinkGroup, INavLink } from "@fluentui/react";
import { configuration } from "../../utils/configuration";

import styles from "./NavigationMenu.module.scss";

import easLogoLight from "../../assets/images/EASX_Logo_Light.svg";
import easLogoDark from "../../assets/images/EASX_Logo_Dark.svg";
import devStamp from "../../assets/images/dev-stamp.png";
import testStamp from "../../assets/images/test-stamp.png";
import { darkTheme, lightTheme } from "../../themes";

const stampImage =
  configuration.Environment === "test"
    ? testStamp
    : configuration.Environment === "dev"
    ? devStamp
    : null;

export const NavigationMenu: React.FC<{
  appName: string;
  handleLinkClick: (
    history: any
  ) => (
    ev?: React.MouseEvent<Element, MouseEvent> | undefined,
    item?: INavLink | undefined
  ) => void;
  isDarkTheme: boolean;
  navLinkGroups: INavLinkGroup[];
  setIsDarkTheme: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({
  appName,
  handleLinkClick,
  isDarkTheme,
  navLinkGroups,
  setIsDarkTheme,
}) => {
  const history = useHistory();

  const onChangeThemes = useCallback(() => {
    setIsDarkTheme((isDark) => {
      localStorage["isDarkTheme"] = !isDark;
      return !isDark;
    });
  }, [setIsDarkTheme]);

  const handleRenderLink: IRenderFunction<INavLink> = (
    props: any,
    defaultRender: any
  ) => (
    <>
      {defaultRender ? defaultRender(props) : null}
      {props?.url.startsWith("http") && (
        <Icon className={styles.openInNewTabIcon} iconName="openInNewTab" />
      )}
    </>
  );

  return (
    <>
      <div
        className={`${styles.NavigationHeader}`}
        onClick={() => history.push("/")}
      >
        <img
          src={isDarkTheme ? easLogoDark : easLogoLight}
          alt="EAS"
          className={`${styles.headerImage}`}
        ></img>
        {stampImage && (
          <img
            src={stampImage}
            alt="EAS"
            className={`${styles.logoOverlay}`}
          ></img>
        )}
        {appName}
      </div>
      <div className={styles.navigationContent}>
        <Nav
          groups={navLinkGroups}
          //   selectedKey={location.pathname}
          onRenderLink={handleRenderLink}
          onLinkClick={handleLinkClick(history)}
        />
        <Stack
          horizontal
          tokens={{ childrenGap: 5 }}
          className={styles.themeSwitchPanel}
        >
          <div
            onClick={onChangeThemes}
            title={isDarkTheme ? 'Switch to light mode' : 'Switch to dark mode'}
            className={styles.themeSwitch}
          >
            <Icon
              iconName={isDarkTheme ? "Sunny" : "ClearNight"}
              style={{ color: (isDarkTheme ? lightTheme : darkTheme).semanticColors.bodyBackground }}
            />
          </div>
        </Stack>
      </div>
    </>
  );
};
