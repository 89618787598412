import React, { useEffect, useState } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import { initializeIcons } from "@fluentui/font-icons-mdl2";
import { Stack, ThemeProvider, loadTheme } from "@fluentui/react";
import { darkTheme, lightTheme } from "../../themes";
import "../../styles/App.css";
import styles from "./BaseLayout.module.scss";
import { RouteType } from "../../types";
import { generateThemeCssVars } from "../../utils/generateColorVariables";

initializeIcons();

export const BaseLayout: React.FunctionComponent<{
  pages: RouteType[];
  renderNavigationMenu: (
    isDarkTheme: boolean,
    setIsDarkTheme: React.Dispatch<React.SetStateAction<boolean>>
  ) => JSX.Element;
  renderErrorHeader?: () => JSX.Element;
}> = ({ pages, renderNavigationMenu, renderErrorHeader }) => {
  const [isDarkTheme, setIsDarkTheme] = useState<boolean>(
    localStorage["isDarkTheme"] === "true"
  );

  useEffect(() => {
    document.body.className = `theme-${isDarkTheme ? "dark" : "light"}`;
    const themeVarsStyle = document.createElement("style");
    themeVarsStyle.id = "sculpt-theme-vars";
    themeVarsStyle.innerHTML = `:root { 
        color-scheme: ${isDarkTheme ? "dark" : "light"};
        ${generateThemeCssVars(isDarkTheme ? darkTheme : lightTheme)} 
      }`;
    document.head.appendChild(themeVarsStyle);
    loadTheme(isDarkTheme ? darkTheme : lightTheme);
    return () => {
      document.head.removeChild(themeVarsStyle);
    };
  }, [isDarkTheme]);

  return (
    <BrowserRouter>
      <ThemeProvider
        applyTo="body"
        theme={isDarkTheme ? darkTheme : lightTheme}
        className={isDarkTheme ? "darkTheme" : "lightTheme"}
      >
        {renderErrorHeader && renderErrorHeader()}
        <div className={styles.globalWrapper}>
          <Stack className={styles.navigationWrapper}>
            {renderNavigationMenu(isDarkTheme, setIsDarkTheme)}
          </Stack>
          <div className={styles.routerWrapper}>
            {pages.map((page, index) => (
              <Route
                key={index}
                exact={page.exact}
                render={page.render}
                component={page.component}
                path={page.path}
              ></Route>
            ))}
          </div>
        </div>
      </ThemeProvider>
    </BrowserRouter>
  );
};
